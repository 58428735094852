<template>
  <Layout>
    <div class="container">
      <h1 class="is-clearfix">
        <span class="is-pulled-left">Logs</span>
      </h1>
      <section class="main-table-container">
        <template v-if="logs.length">
          <table class="table is-striped is-fullwidth">
            <thead>
              <tr>
                <template v-for="field in fields">
                  <td :key="field.label">{{ field.label }}</td>
                </template>
                <template>
                  <td>Actions</td>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr v-for="log in logs" :key="log._id">
                <template>
                  <td>{{log.owner.name.full}}</td>
                </template>
                <template>
                  <td>{{log.initialState.sequentialId}}</td>
                </template>
                <template>
                  <td>{{log.action}}</td>
                </template>
                <template>
                  <td>{{log.modified}}</td>
                </template>
                <td class="actions">
                  <div class="field has-addons">
                    <p class="control">
                      <router-link
                        :to="
                          '/log/' + log._id
                        "
                        class="button tooltip is-tooltip-bottom"
                        data-tooltip="View record"
                      >
                        <span class="icon">
                          <icon :icon="['fas', 'eye']" />
                        </span>
                      </router-link>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-else>
          <div style="text-align: center; padding: 2rem 0;">No Records Found</div>
        </template>
      </section>
      <modal v-show="showModalConfirmation" @closeModal="closeModal" v-bind="modalConfig" />
    </div>
  </Layout>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      logs: [],
      logId: this.$route.params.id,
      showModalConfirmation: false,
      fields: [
        { label: 'User' },
        { label: 'Prefix ID' },
        { label: 'Action' },
        { label: 'Date' }
      ],
      modalConfig: {
        bodyText: 'Logs',
        titleText: 'Logs was not found',
        buttons: {
          confirm: {
            text: 'Ok',
            class: 'is-primary'
          }
        }
      }
    };
  },
  components: {
    Layout: () => import('@/router/layouts/Main')
  },
  methods: {
    async getLogData() {
      try {
        const response = await axios.get(
          $formsConfig.core.api.logs.replace('__logId__', this.logId)
        );
        this.logs = response.data;
      } catch (error) {
        this.showModalConfirmation = true;
      }
    },
    closeModal() {
      this.$router.push('/forms');
    }
  },
  computed: {},
  async created() {
    if (this.logId) {
      await this.getLogData();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~bulma-switch';
@import '~bulma/sass/components/card';
.container {
  height: 100%;
  h1 {
    position: inherit;
    top: 0;
    right: 0;
  }
  > .columns {
    height: 100%;
    margin-top: 0;
    > .column {
      height: 100%;
    }
  }
  #filterContainer {
    clear: both;
    margin: 1.75rem auto;
    .card-header {
      .card-header-title {
        margin: 0;
      }
      .button {
        align-self: center;
        border-radius: 150px;
        margin: 0 0.75rem;
      }
    }
    .columns {
      &.rangeDateContainer {
        margin-top: 2rem;
      }
      &.logicalOperator {
        margin-top: -1.75rem;
      }
      .field {
        padding: 0.75rem;
        &.searchFor {
          flex-basis: 23%;
          .select {
            width: 100%;
            select {
              width: 100%;
            }
          }
        }
        &.searchValue {
          flex-basis: 33%;
        }
        &.deleteBtn {
          flex-basis: 33%;
          align-self: center;
          p {
            margin: 0;
          }
        }
        &.rangeDateHeader {
          flex-basis: 23%;
          align-self: center;
          margin: 0;
        }
      }
    }
    .card-footer {
      padding: 0.75rem;
      justify-content: flex-end;
      .button {
        margin: 0 0.75rem;
      }
    }
  }
}
.headers {
  font-size: 14px;
  font-weight: bold;
  color: $tittle-font;
}
</style>
